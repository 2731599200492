
import { defineComponent, onMounted, reactive, ref, computed } from "vue";
import * as moment from "moment";
import { ElNotification, ElMessageBox } from 'element-plus'
import { useI18n } from "vue-i18n";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";

import GalerieImage from "@/views/still/deploy/galerieImage.vue";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "Affichage d'un ticket",
  emits: ["reloadTicket", "updateStatusFromChild"],
  props: {
    objTicket : {
      default: {"tic_knum_societe": "", "tic_c_label": "", "tic_c_serv": [], "tic_list_matos": [],"tic_staging": [], "integration": []},
    },
    modeTic : {
      default: 0,
    },
  },
  components: {
    GalerieImage,
    Datatable    
  },
  setup(props, { emit }) {
    //moment.default.locale("fr");
    const state = reactive({
      loaderEnabled: true,
      objTicket: props.objTicket as any,
      modeTic: props.modeTic as any,
      mAdresse: [] as any,
      activeStep : 0,
      stagingOnOff: 0,
      loadingDatatable: 0,
      allContacts: [] as any,
      readOnly: false,
      currIndex: 0,
      compIntTis: [] as any,
    });
    const { t } = useI18n()
   
    onMounted(async () => {
      // console.log('objet', state.objTicket)

      let count = 0;
      state.objTicket.tic_c_serv.forEach(async (val) => { 

        val.old_tis_notes = val.tis_notes;

        val.cat_json_custom_values = isJSON(val.cat_json_custom_values) ? JSON.parse(val.cat_json_custom_values) : val.cat_json_custom_values;

        val.cat_json_custom = isJSON(val.cat_json_custom) ? JSON.parse(val.cat_json_custom) : val.cat_json_custom;

        if (val.knum_lieu && val.code) await returnAdresse(val.code, val.knum_lieu, val.knum_contact);
        count += 1;

        // if (val.tis_status != 'En cours') state.activeStep += 1;
        if (getStatusA(val.code, val.tis_seq) == 1 ) state.activeStep += 1;


        if( count == state.objTicket.tic_c_serv.length) state.loaderEnabled = false;

        if (val.tis_status == 'Réalisé') state.readOnly = true;

        if (state.objTicket.integration && state.objTicket.integration.filter(el => el.int_knum_ticket_catserv == val.tis_seq).length > 1) {
          let totalGroupe = 0;
          for (let i = 0; i < state.objTicket.integration.length; i++) {
            if (state.objTicket.integration[i].int_knum_ticket_catserv == val.tis_seq) {
              totalGroupe += parseFloat(state.objTicket.integration[i].tot_commande);
            }
          }
                    
          // Assigner totalGroupe à toutes les lignes correspondantes
          for (let i = 0; i < state.objTicket.integration.length; i++) {
            if (state.objTicket.integration[i].int_knum_ticket_catserv == val.tis_seq) {
              state.objTicket.integration[i].tot_commande = totalGroupe.toString();
            }
          }
        }
      });

      let myContacts = await mAxiosApi.prototype.getAxios("/getContacts");
      state.allContacts = myContacts.results;

      state.objTicket.integration.forEach(line => {
        getMyTotal(line.int_knum_contact, line.int_knum_ticket_catserv);
        getTotOthers(line.int_knum_ticket_catserv, line.int_knum_lng_commande);
      });
      getSolde();

      state.currIndex = state.objTicket.integration.findIndex(el => el.int_knum_ticket_catserv == state.objTicket.tic_c_serv[0].tis_seq);

    });


    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };

    const byCode = (code) => {
      if (state.objTicket) return state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.code == code)]
    }

    function isJSON(str) {
        try {
            return (JSON.parse(str) && !!str);
        } catch (e) {
            return false;
        }
    }

    const reloadTicket = () => {
      emit("reloadTicket", 1);
    };

    const delFile = (tif_seq,tis_seq) => {
      const form = {
       tif_knum_ticket: state.objTicket.tic_seq,
       tif_knum_ticket_services: tis_seq,
        tif_seq: tif_seq,
      }

      mAxiosApi
        .post("/delFileTicketService", form)
        .then( async() => {
            emit("reloadTicket", 1);
        })
    };

    const updateCom = (tis_seq, newCom) => {
      const form = {
       ticSeq: state.objTicket.tic_seq,
       tisSeq: tis_seq,
       newCom: newCom,
      }

      mAxiosApi
        .post("/updateComTicketService", form)
        .then( async() => {
          emit("reloadTicket", 1);
          ElNotification({
            title: t("Succès"),
            message: t("Commentaires modifié"),
            type: 'success',
          })

        })
    };
     

    const returnFilesCode = (code) => {
      if (!state.objTicket) return false;
      const fichiers = state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.code == code)].fichiers;

      if (isJSON(fichiers)) {
        if (JSON.parse(fichiers).length > 0) {
          let filesArray = JSON.parse(fichiers).filter(el => el.name.indexOf('.jpg') <= 0);
          filesArray = filesArray.filter(el => el.name.indexOf('.png') <= 0);
          filesArray = filesArray.filter(el => el.name.indexOf('.jpeg') <= 0);
          filesArray = filesArray.filter(el => el.name.indexOf('.JPG') <= 0);
          filesArray = filesArray.filter(el => el.name.indexOf('.gif') <= 0);
          return filesArray;
        }  
        return false;
      } else {
        if (fichiers.length > 0) return fichiers;
        return false;
      }
    }

    const returnImgCode = (code) => {
      if (!state.objTicket) return false;
      const fichiers = state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.code == code)].fichiers;

      if (isJSON(fichiers)) {
        if (JSON.parse(fichiers).length > 0) {
          let filesArray = JSON.parse(fichiers).filter(el => el.name.indexOf('.jpg') > 0 || el.name.indexOf('.jpeg') > 0 || el.name.indexOf('.JPG') > 0 || el.name.indexOf('.png') > 0 || el.name.indexOf('.gif') > 0);
          return filesArray;
        }  
        return false;
      } else {
        if (fichiers.length > 0) return fichiers;
        return false;
      }
    }

    const byCodeAdr = (code) => {
      if (state.mAdresse) return state.mAdresse[state.mAdresse.findIndex(element => element.code == code)]
    }
    const returnAdresse = async (code, adr_seq, con_seq) => {
      let mAdresse = await mAxiosApi.prototype.getAxios("/getAdresseContact/" + adr_seq + '/' + con_seq);
      state.mAdresse.push({ code: code, adresse: mAdresse.records } )
    }

    const applyChange = (tis_seq, values_custom, name, value) => {

      const form = {
       ticSeq: state.objTicket.tic_seq,
       tisSeq: tis_seq,
       values_custom: values_custom,
       name: name,
       value: value,
      }

      mAxiosApi
        .post("/updateCustomValues", form)
        .then( async() => {
          //emit("reloadTicket", 1);
          ElNotification({
            title: t("Succès"),
            message: t("Valeur de '") + name + t("' modifiée."),
            type: 'success',
          })
      })

    }

    const getStatus = (code) => {
      /*
       if (!state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].tis_status) return '';
      return state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].tis_status;
      */
      if (!state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)]?.tis_status) return '';
      return state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)]?.tis_status;
    }

    const getStatusA = (code, tisSeq = null) => {

      // const currTis = state.objTicket.tic_c_serv.find(element => 
      //   element.xCheckUser == true && 
      //   element.code == code && 
      //   (element.tis_seq == tisSeq)
      // );

      // if (!currTis || !currTis.tis_status) return -1;

      // let catStatus = currTis.cat_status;
      // const arrayCat = JSON.parse(catStatus);

      // return arrayCat.status.find(element => element.label == currTis.tis_status)?.next;

      /*
       if (!state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].tis_status) return '';
      return state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].tis_status;
      */
      if (!state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)]?.tis_status) return -1;
      let catStatus;
      if (tisSeq) {
        catStatus = state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code && element.tis_seq == tisSeq)].cat_status;
      } else {
        catStatus = state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].cat_status;
      }
      
      const arrayCat = JSON.parse(catStatus);
      // console.log('status : ',arrayCat.status.find(element => element.label==state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].tis_status).next);

      return arrayCat.status.find(element => element.label==state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)]?.tis_status).next;
    }

    function b64toBlob(dataURI) {
        
        var byteString = atob(dataURI.split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'text/plain' });
    }

    const downloadFile = async (item) => {
      
      console.log("En attente");
      let myList = await mAxiosApi.prototype.getAxios("/getFileTicket/" + item.id + '/' + item.name);
//      const myBlob = myList.results[0].tif_base64.substring(myList.results[0].tif_base64.indexOf('base64,')+7);

      // console.log(myList.results[0].tif_base64.blob());
      /*
      const data = encodeURI(myList.results[0].tif_base64);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", item.name);
      link.click();
      */
      
      const csvData = b64toBlob(myList.results[0].tif_base64);
      const csvUrl = URL.createObjectURL(csvData);
      const element = document.createElement("a");
      element.setAttribute("href",csvUrl);
      element.setAttribute("download", item.name);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    };

    const tableHeader = computed(() => {
      const headers = [
        {
          name: 'Désignation',
          key: "int_designation",
          sortable: true,
        },
        {
          name: 'Date',
          key: 'int_date_inter',
          sortable: true
        },
        { name: 'Statut',
          key: 'int_status',
          sortable: true
        },
        {
          name: 'Intervenant',
          key: 'presta_nom_prenom',
          sortable: true
        },
        {
          name: 'Jours',
          key: 'int_qte',
          sortable: true
        }
      ];

      if(modeDisplay.value == 'Edit') {
        headers.push({
          name: 'Actions',
          key: 'int_seq',
          sortable: true 
        });
      }

      return headers;
    })
      

    function formatNumber(value) {
      if (value === undefined || value === null) {
        return '';
      }
      let [integer, decimal] = value.toString().split('.');
      return decimal && decimal.startsWith('0') ? integer : `${integer}.${decimal ? decimal[0] : ''}`;
    }

    function addPresta () {

      const form = {
        tot_commande: state.objTicket.integration[currIndex.value].tot_commande,
        myTot: state.objTicket.integration[currIndex.value].myTot,
        othersTot: state.objTicket.integration[currIndex.value].othersTot,
        solde: state.objTicket.integration[currIndex.value].solde,
        int_seq: 0,
        int_date_creation: state.objTicket.integration[currIndex.value].int_date_creation,
        int_knum_user_creation: state.objTicket.integration[currIndex.value].int_knum_user_creation,
        int_knum_lng_commande: state.objTicket.integration[currIndex.value].int_knum_lng_commande,
        int_qte: 0,
        int_date_inter: state.objTicket.integration[currIndex.value].int_date_inter,
        int_knum_contact: state.objTicket.integration[currIndex.value].int_knum_contact,
        int_status: 'A Planifier',
        int_designation: state.objTicket.integration[currIndex.value].int_designation,
        int_knum_ticket_catserv: state.objTicket.integration[currIndex.value].int_knum_ticket_catserv,
        all_status: state.objTicket.integration[currIndex.value].all_status,
        intervenant: state.objTicket.integration[currIndex.value].intervenant,
        presta_nom_prenom: state.objTicket.integration[currIndex.value].presta_nom_prenom,
        
      }

      mAxiosApi
        .post("/addLigneIntegration", form)
        .then(async (response) => {
          const erreurs = response.data.erreurs;
          if (erreurs && erreurs.length > 0) {
            ElMessageBox.alert(
              t(erreurs[0].message), t("Attention !"),
              { confirmButtonText: t("Ok"), type: 'warning' }
            );
            return;
          } else {

            form.int_seq = response.data.int_seq;
            state.objTicket.integration.push(form);
            state.loadingDatatable ++;
            
            ElNotification({
              title: t("Succès"),
              message: t("Intégration ajoutée"),
              type: 'success',
            })
          }
        })      
    }

    const deletePresta = (int_seq) => {
      const form = {
        int_seq: int_seq,
      }

      state.objTicket.integration.splice(state.objTicket.integration.findIndex(el => el.int_seq == int_seq), 1);

      state.loadingDatatable ++;

      mAxiosApi
        .post("/deleteLigneIntegration", form)
        .then(async () => {          
          ElNotification({
            title: t("Succès"),
            message: t("Intégration supprimée"),
            type: 'success',
          })

          getMyTotal(state.objTicket.integration[currIndex.value].int_knum_contact, state.objTicket.integration[currIndex.value].int_knum_ticket_catserv);
          getTotOthers(state.objTicket.integration[currIndex.value].int_knum_ticket_catserv, state.objTicket.integration[currIndex.value].int_knum_lng_commande);
          getSolde();
        })
    }

    const updateLigneIntegration = (dataEl) => {
      if (dataEl.int_knum_contact == null) {
        ElMessageBox.alert(
          t('Veuillez définir le prestataire avant de modifier la prestation svp.'), t("Erreur"),
          { confirmButtonText: t("Ok"), type: 'error', }
        );
        return;
      }

      if (dataEl.int_qte < 0) {
        ElMessageBox.alert(
          t('La quantité ne peut pas être négative.'), t("Erreur"),
          { confirmButtonText: t("Ok"), type: 'error', }
        );
        return;
      }

      if (dataEl.int_status || dataEl.int_knum_contact) {
        state.objTicket.integration.find(el => el.int_seq == dataEl.int_seq).int_status = dataEl.int_status;
        state.objTicket.integration.find(el => el.int_seq == dataEl.int_seq).int_knum_contact = dataEl.int_knum_contact;
      }

      const form = {
        int_seq: dataEl.int_seq,
        int_date_inter: dataEl.int_date_inter,
        int_designation: dataEl.int_designation,
        int_qte: dataEl.int_qte,
        int_knum_contact: dataEl.int_knum_contact,
        int_status: dataEl.int_status,
        int_knum_lng_commande: state.objTicket.integration[currIndex.value].int_knum_lng_commande,
        int_knum_ticket_catserv: dataEl.int_knum_ticket_catserv
      }

      // console.log('form : ', form);

      mAxiosApi
        .post("/updateLigneIntegration", form)
        .then(async (response) => {

          const block = response.data.block;
          const erreurs = response.data.erreurs;
          const modification = response.data.modification;

          if (state.objTicket.integration[0].solde === 0 && (erreurs && erreurs.some(err => err.type === 'cloture'))) {
            updateStatusFromChild(state.objTicket.tic_c_serv[0]);

            if (state.objTicket.tic_c_serv[0].tis_status == 'Réalisé') {
              state.readOnly = true;
            }
          }

          if (block) {
            ElMessageBox.alert(
              t(block[0].message), t("Attention !"),
              { confirmButtonText: t("Ok"), type: 'error' }
            );
            return;
          }

          if (erreurs && erreurs.length > 0) {
            for (const [fullKey, value] of Object.entries(modification)) {
              const [section, key] = fullKey.split('.');


              if (state.objTicket[section]) {
                if (section === 'tic_c_serv') {

                  const target = state.objTicket[section];
                  
                  if (target) {
                    target[0][key] = value;
                  }

                } else {

                  const target = state.objTicket[section].find(el => el.int_seq == dataEl.int_seq);

                  if (target) {
                    target[key] = value;
                  }
                }
                getMyTotal(dataEl.int_knum_contact, dataEl.int_knum_ticket_catserv);
                getTotOthers(dataEl.int_knum_ticket_catserv, dataEl.int_knum_lng_commande);
                getSolde();
              }
             
            }

            ElMessageBox.alert(
              t(erreurs[0].message), t("Attention !"),
              { confirmButtonText: t("Ok"), type: 'warning' }
            );
          } else {
            
            ElNotification({
              title: t("Succès"),
              message: t("Intégration modifiée."),
              type: 'success',
                });
          }
          
          if (state.objTicket.tic_c_serv[0].tis_status == 'Réalisé') {
            state.readOnly = true;
          }
          
        });

        getMyTotal(dataEl.int_knum_contact, dataEl.int_knum_ticket_catserv);
        getTotOthers(dataEl.int_knum_ticket_catserv, dataEl.int_knum_lng_commande);
        getSolde();

    }

    const compIntTis = (tisSeq) => {
      return state.objTicket.integration.filter(el => el.int_knum_ticket_catserv == tisSeq);
    };

    const getMyTotal = (technicienSeq, tisSeq) => {
      let myTot = 0;
      state.objTicket.integration.filter(el => el.int_knum_contact == technicienSeq && el.int_knum_ticket_catserv == tisSeq).forEach(line => {
        myTot += Number(line.int_qte);
      });
      state.objTicket.integration.forEach(line => {
        if (line.int_knum_contact == technicienSeq && line.int_knum_ticket_catserv == tisSeq) {
          line.myTot = myTot;
        }
      });
    };

    const getTotOthers = (tisSeq, lngCommande) => {
      let othersTot = 0;
      state.objTicket.integration
        .filter(el => el.int_knum_ticket_catserv != tisSeq && el.int_knum_lng_commande == lngCommande)
        .forEach(line => {
        othersTot += Number(line.int_qte);
      });
      state.objTicket.integration.forEach(line => {
        if (line.int_knum_ticket_catserv == tisSeq && line.int_knum_lng_commande == lngCommande) {
          line.othersTot = othersTot;
        }
      });
    };

    const getSolde = () => {
      let solde = 0;

      const totCommande = state.objTicket.integration[currIndex.value].tot_commande;
      const othersTot = state.objTicket.integration[currIndex.value].othersTot;
      const myTot = state.objTicket.integration[currIndex.value].myTot;
      
      
      solde = totCommande - (othersTot + myTot);
      state.objTicket.integration.forEach(line => {        
          line.solde = solde;
      });
      
      // console.log('solde : ', solde);
    }  

    const getTotLigneCommande = (intKnumLngCommande) => {
      let totCommande = 0;
      state.objTicket.integration.forEach(line => {
        if (line.int_knum_lng_commande == intKnumLngCommande) {
          totCommande += Number(line.int_qte);
        }
      });
      return totCommande;
    }
    
    const updateStatusFromChild = (data) => {
      emit("updateStatusFromChild", data);
    };

    const modeDisplay = computed(() => { 
      if (!state.modeTic || state.readOnly) {
        return 'ReadOnly';
      } else {
        return 'Edit';
      }
    });

    const currIndex = computed(() => {
      return state.objTicket.integration.findIndex(el => el.int_knum_ticket_catserv == state.objTicket.tic_c_serv[0].tis_seq);
    });

   return {
      state,
      byCode,
      formatDate,
      returnAdresse,
      byCodeAdr,
      getStatus,
      getStatusA,
      downloadFile,
      returnFilesCode,
      returnImgCode,
      reloadTicket,
      delFile,
      updateCom,
      applyChange,
      tableHeader,
      formatNumber,
      addPresta,
      deletePresta,
      updateLigneIntegration,
      getMyTotal,
      getTotOthers,
      getSolde,
      updateStatusFromChild,
      modeDisplay,
      currIndex,
      compIntTis,
      getTotLigneCommande
    };
  },
});
