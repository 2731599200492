<template>

  <div v-if="!state.loaderEnabled">
      <div class="bg-white w-100% mb-7 p-3" style="border-radius: 5px; text-align: center" v-if="state.objTicket.tic_seq > 0">
        <b>{{$t("TICKET")}} #{{ state.objTicket.tic_seq }} {{$t("du")}} {{ formatDate(state.objTicket.tic_d_creation) }}</b>
        <b v-if="state.objTicket.tic_c_label"> - {{ state.objTicket.tic_c_label }}</b>
      </div>
      <div class="bg-white w-100% mb-7 p-3" style="border-radius: 5px" v-if="state.objTicket.tic_seq > 0">

        <el-steps :active="state.activeStep" simple finish-status="success">
          <el-step :title="$t('Validation')" v-if="state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == 'validation')]"/>
          <el-step :title="$t('Staging')" v-if="state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == 'staging')]" />
          <el-step :title="$t('Expédition')" v-if="state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == 'exped')]"/>
          <el-step :title="$t('Déploiement')" v-if="state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == 'deploy')]"/>

          <template v-for="(item, i) in state.objTicket.tic_c_serv.filter(element => element.xCheckUser == true && element.code.indexOf('custom') >=0 )" :key="i" >
            <el-step :title="byCode(item.code).name" />
          </template>

        </el-steps>
        
      </div>

      <div style="padding-left: 15px">

        <div v-if="state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == 'validation')]"
          style="display: flex; align-items: center; align-items: flex-start;" class="mb-10">

          <div class="stepper-icon w-30px h-30px bg-light-dark pastille-ticket" v-if="getStatusA('validation') <= 0">
            <span class="svg-icon svg-icon-3 svg-icon-dark pulse pulse-primary" >
              <inline-svg style="margin-top:6px; margin-left: 6px" src="media/icons/duotune/general/gen012.svg" />
            </span>
          </div>
          <div class="stepper-icon w-30px h-30px bg-success pastille-ticket" v-else>
            <span class="svg-icon svg-icon-3 svg-icon-white pulse pulse-primary">
              <inline-svg style="margin-top:6px; margin-left: 6px" src="media/icons/duotune/arrows/arr085.svg" />
            </span>
          </div>
          <div class="px-5" style="border-left: 1px dashed #e4e6ef; width: 100%">
            <h3 style="text-transform: uppercase; margin-left: 10px">{{$t("Validation du ticket")}}</h3>
            <div class="text-gray-900 mt-5 bg-white rounded p-10" style="width: 100%" v-if="getStatusA('validation') == '-1'">
              <b>
                <el-alert :title="$t('Ce ticket nécessitera dans un premier temps une validation de Stillnetwork')" class="h-60px bg-light-primary text-primary" type="info" description="" :closable="false"/>
              </b>
                <blockquote class="blockquote border-0 p-0 mx-7 mt-10">
                  <p class="font-italic lead fs-6"> <i class="fa fa-quote-left mx-3  mr-3 text-dark"></i><span>{{$t("Votre interlocuteur Stillnetwork vérifiera la bonne cohérence de cette demande, aucune action ne sera déclenchée avant sa validation.")}}</span><i class="fa fa-quote-right mx-3  mr-3 text-dark  "></i></p>
                </blockquote>
            </div>
            <div class="text-gray-900 mt-5 bg-white rounded p-10" style="width: 100%" v-if="getStatusA('validation') == 0">
              <b>
                <el-alert :title="$t('Ce ticket est en attente de validation')" class="h-60px" type="warning" description="" :closable="false"/>
              </b>
                <blockquote class="blockquote border-0 p-0 mx-7 mt-10">
                  <p class="font-italic lead fs-6"> <i class="fa fa-quote-left mx-3  mr-3 text-warning"></i><span>{{$t("Nos équipes ont reçu une notification et traiteront votre demande dans les plus brefs délais.")}}</span><i class="fa fa-quote-right mx-3  mr-3 text-warning  "></i></p>
                </blockquote>
            </div>

            <div class="text-gray-900 mt-5 bg-white p-10" style="width: 100%" v-if="getStatusA('validation') == 1">
              <b><el-alert :title="$t('Ce ticket a été validé')" class="h-60px" type="success" description="" :closable="false"/></b>
            </div>
            <div v-if="byCode('validation').tis_notes" class="text-gray-900 bg-white px-10 pb-10" style="width: 100%" >
              <div class="mb-2"><b>{{$t("Commentaires Stillnetwork :")}}</b></div>
              <el-alert class="p-5" type="info" :title="byCode('validation').tis_notes" :closable="false"/>
            </div>

            <div v-if="returnFilesCode('validation')" class="mb-5 bg-white rounded px-10 pt-5 pb-10 mt-5 ">
              <div class='mt-7 mb-3'>{{$t("Fichier(s) concernant la validation :")}}</div>
              <template v-for="(item, index) in returnFilesCode('validation')" :key="index">
                <a class="btn btn-sm btn-light-primary px-4 py-2 me-4 mt-1 mb-2  mx-7 btn-dl mx-7" @click="downloadFile(item)" >
                  <i class="las la-file-alt fs-2 me-2"></i>
                  {{$t("Fichier :")}} {{ item.name }}
                </a>
              </template>
            </div>

          </div>

          
        </div>


        <div v-if="state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == 'staging')]"
          style="display: flex; align-items: center; align-items: flex-start;" class="mb-10">

          <div class="stepper-icon w-30px h-30px bg-light-dark pastille-ticket" v-if="getStatusA('staging') <= 0">
            <span class="svg-icon svg-icon-3 svg-icon-dark pulse pulse-primary" >
              <inline-svg style="margin-top:6px; margin-left: 6px" src="media/icons/duotune/general/gen012.svg" />
            </span>
          </div>
          <div class="stepper-icon w-30px h-30px bg-success pastille-ticket" v-else>
            <span class="svg-icon svg-icon-3 svg-icon-white pulse pulse-primary">
              <inline-svg style="margin-top:6px; margin-left: 6px" src="media/icons/duotune/arrows/arr085.svg" />
            </span>
          </div>

          <div class="px-5" style="border-left: 1px dashed #e4e6ef; width: 100%">
            <h3 style="text-transform: uppercase; margin-left: 10px">{{$t("Staging")}}</h3>
                       

            <el-container>
              <el-main class="p-0">

                <div v-if="byCode('staging').tis_notes" class="text-gray-900 bg-white p-10 mt-3" style="width: 100%" >
                  <div class="mb-2"><b>{{$t("Commentaires Stillnetwork :")}}</b></div>
                  <el-alert class="p-5" type="info" :title="byCode('staging').tis_notes" :closable="false"/>
                </div>

                <div class="text-gray-900 mt-5 bg-white rounded  p-10 mainStaging" :class="{'overflow-stagging': !state.stagingOnOff}" style="width: 100%;">
                  <b>{{$t("Stager le  matériel avec les configurations suivantes :")}}</b>
                  <ul class="mt-3 mb-10" v-for="(item, i) in state.objTicket.tic_staging" :key="i">           
                    <li>
                      <div class="text-gray-400"> <span class="w-150px  fs-6 text-dark">{{ item.loc_reference }}</span>  </div>
                      <div class="fs-7 text-gray-500 mb-2" style="display: -webkit-box;-webkit-line-clamp: 1; -webkit-box-orient: vertical;  overflow: hidden;  text-overflow: ellipsis;">{{ item.loc_designation }}</div>
                      <template v-for="(item, index) in item.fichiers" :key="index">
                          <a class="btn btn-sm btn-light-primary px-4 py-2 me-4 mt-1 mb-2  mx-7 btn-dl" @click="downloadFile(item)" >
                            <i class="las la-file-alt fs-2 me-2"></i>
                            {{$t("Fichier :")}} {{ item.name }}
                          </a>
                      </template>

                      <blockquote class="blockquote border-0 p-0" v-if="item.commentaires">
                        <p class="font-italic lead fs-6"> <i class="fa fa-quote-left fs-8 text-primary" style="margin-right: 7px"></i><span v-html="item.commentaires.replace(/(\r\n|\n\r|\r|\n)/g, '<br>')"></span><i class="fa fa-quote-right fs-8 text-primary" style="margin-left: 7px"></i></p>
                      </blockquote>
                    </li>
                  </ul>
              </div>
              </el-main>
              <el-footer class="w-100%" style="height: 22px; background: #f9f9f9 !important; border: 1px solid #ebe8e8;cursor: pointer;" >
                <div style="text-align: center; line-height: 22px" class="fs-7 p-0" v-if="state.stagingOnOff==0"  @click="state.stagingOnOff=1">{{$t("Afficher la liste complète...")}}</div>
                <div style="text-align: center; line-height: 22px" class="fs-7 p-0" v-if="state.stagingOnOff==1" @click="state.stagingOnOff=0">{{$t("Masquer une partie de la liste...")}}</div>
              </el-footer>
            </el-container>

          </div>
        </div>


        <div v-if="state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == 'exped')]"
          style="display: flex; align-items: center; align-items: flex-start;">
          <div class="stepper-icon w-30px h-30px bg-light-dark pastille-ticket" v-if="getStatusA('exped') <= 0">
            <span class="svg-icon svg-icon-3 svg-icon-dark pulse pulse-primary" >
              <inline-svg style="margin-top:6px; margin-left: 6px" src="media/icons/duotune/general/gen012.svg" />
            </span>
          </div>
          <div class="stepper-icon w-30px h-30px bg-success pastille-ticket" v-else>
            <span class="svg-icon svg-icon-3 svg-icon-white pulse pulse-primary">
              <inline-svg style="margin-top:6px; margin-left: 6px" src="media/icons/duotune/arrows/arr085.svg" />
            </span>
          </div>

          <div class="px-5" style="border-left: 1px dashed #e4e6ef; width: 100%">
            <h3 style="text-transform: uppercase; margin-left: 10px">{{$t("Expédition")}}</h3>

            <div class="text-gray-900 mt-5 bg-white rounded p-10" style="width: 100%">

              <b><el-alert :title="$t('Ce ticket a été expédié')" class="h-60px mb-5" type="success" description="" :closable="false" v-if="!(getStatusA('exped') <= 0)" /></b>

              <b>{{$t("Sortie du stock du matériel suivant :")}}</b>
              <ul class="mt-3 mb-10" v-for="(item, i) in state.objTicket.tic_list_matos" :key="i">           
                <li>
                  <div class="text-gray-400"> 
                    <span class="w-150px  fs-6 text-dark" v-if="item.liv_ref_secondaire"><b>{{ item.liv_ref_secondaire }}</b><br></span>  
                    <span class="w-150px  fs-6 text-dark" v-else>{{ item.loc_reference }}</span>  
                  </div>
                  <div class="fs-7 text-gray-500 mb-2" style="display: -webkit-box;-webkit-line-clamp: 1; -webkit-box-orient: vertical;  overflow: hidden;  text-overflow: ellipsis;">
                    {{ item.loc_designation }}
                  </div>
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                      <span class="me-5 fs-7 badge badge-light-warning  text-gray-800">
                        {{$t("Quantité :")}} {{ item.qte_exped }}
                    </span>

                    <span :class="{'badge-light-danger text-danger': item.qte_max - item.qte_exped == 0, 'badge-light-dark text-gray-800': item.qte_max - item.qte_exped > 0}" class="me-5 fs-7 badge " >
                      {{$t("Prévision de stock :")}} {{ item.qte_max - item.qte_exped }}
                    </span>
                    </div>
                    <div>
                      <span v-if="item.liv_tracking">
                        <a class="btn btn-sm btn-secondary me-5" :href="item.url_tracking.replace('{{token}}', item.liv_tracking)" target="_blank">
                          <i class="bi bi-truck fs-3"></i>
                        </a>
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
              
              <div v-if="byCode('exped').type_exped == 0"><b>{{$t("Expédition du matériel dés que possible à l'adresse :")}}</b></div>
              <div v-if="byCode('exped').type_exped == 1"><b>{{$t("Expédition du matériel à partir du")}} {{ formatDate(byCode('exped').date1) }} {{$t("à l'adresse :")}}</b></div>
              <div v-if="byCode('exped').type_exped == 2" class="text-primary"><b>{{$t("Blocage du matériel en vue d'une livraison future.")}}</b></div>

              <ul class="mt-3" v-if="byCode('exped').type_exped != 2">           
                  <div class="text-gray-400">
                    <span class="w-150px  fs-6 text-gray-700" v-if="byCodeAdr('exped')" >
                      <span  v-if="byCodeAdr('exped')['adresse'].adr_c_site_label" class='text-primary'>{{ byCodeAdr('exped')['adresse'].adr_c_site_label  }}<br></span>
                      <span>{{ byCodeAdr('exped')['adresse'].adr_ligne_1  }}<br></span>
                      <span v-if="byCodeAdr('exped')['adresse'].adr_ligne_2 ">{{ byCodeAdr('exped')['adresse'].adr_ligne_2  }}<br></span>
                      <span>{{ byCodeAdr('exped')['adresse'].adr_cp  }} - {{ byCodeAdr('exped')['adresse'].adr_ville  }} - {{ byCodeAdr('exped')['adresse'].adr_pays  }}</span>
                      <span v-if="byCodeAdr('exped')['adresse'].con_prenom "><br><br><b>{{$t("Contact :")}}</b> {{ byCodeAdr('exped')['adresse'].con_prenom  }} {{ byCodeAdr('exped')['adresse'].con_nom  }}</span>
                    </span>
                  </div>
              </ul> 

              <template v-if="byCode('exped').desc_globale">
                <div class='mt-7 mb-3'><b>{{$t("Notes spécifiques à destination de l'équipe Stilnetwork gérant le stock :")}}</b></div>
                <blockquote class="blockquote border-0 p-0 mx-7">
                  <p class="font-italic lead fs-6"> <i class="fa fa-quote-left mx-3  mr-3 text-primary"></i><span v-html="byCode('exped').desc_globale.replace(/(\r\n|\n\r|\r|\n)/g, '<br>')"></span></p>
                </blockquote>
              </template>

            </div>


            <div v-if="byCode('exped').tis_notes" class="text-gray-900 bg-white px-10 pb-10" style="width: 100%" >
              <div class="mb-2"><b>{{$t("Commentaires Stillnetwork :")}}</b></div>
              <el-alert class="p-5" type="info" :title="byCode('exped').tis_notes" :closable="false"/>
            </div>

            <div v-if="returnFilesCode('exped')" class="mb-5 bg-white rounded px-10 pt-5 pb-10 mt-5 ">
              <div class='mt-7 mb-3'>{{$t("Fichier(s) concernant l'expédition :")}}</div>
              <template v-for="(item, index) in returnFilesCode('exped')" :key="index">
                <a class="btn btn-sm btn-light-primary px-4 py-2 me-4 mt-1 mb-2  mx-7 btn-dl mx-7" @click="downloadFile(item)" >
                  <i class="las la-file-alt fs-2 me-2"></i>
                  {{$t("Fichier :")}} {{ item.name }}
                </a>
              </template>
            </div>

          </div>
        </div>

        <div class="mt-10" v-if="state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == 'deploy')]"
            style="display: flex; align-items: center; align-items: flex-start;">
          <div class="stepper-icon w-30px h-30px bg-light-dark pastille-ticket" v-if="getStatusA('deploy') <= 0">
            <span class="svg-icon svg-icon-3 svg-icon-dark pulse pulse-primary" >
              <inline-svg style="margin-top:6px; margin-left: 6px" src="media/icons/duotune/general/gen012.svg" />
            </span>
          </div>
          <div class="stepper-icon w-30px h-30px bg-success pastille-ticket" v-else>
            <span class="svg-icon svg-icon-3 svg-icon-white pulse pulse-primary">
              <inline-svg style="margin-top:6px; margin-left: 6px" src="media/icons/duotune/arrows/arr085.svg" />
            </span>
          </div>
          <div class="px-5" style="border-left: 1px dashed #e4e6ef; width: 100%">
            <h3 style="text-transform: uppercase; margin-left: 10px">{{$t("Déploiement")}}</h3>
            <div class="text-gray-900 mt-5 bg-white rounded p-5" style="width: 100%">

              <b><el-alert :title="$t('Ce ticket a été déployé')" class="h-60px mt-2 mb-5" type="success" description="" :closable="false" v-if="!(getStatusA('deploy') <= 0)" /></b>

              <template v-if="byCode('deploy').desc_globale">
                <div class='mt-7 mb-3'>{{$t("Description de votre besoin de déploiement :")}}</div>
                <blockquote class="blockquote border-0 p-0 mx-7">
                  <p class="font-italic lead fs-6"> <i class="fa fa-quote-left mx-3  mr-3 text-primary"></i><span v-html="byCode('deploy').desc_globale.replace(/(\r\n|\n\r|\r|\n)/g, '<br>')"></span></p>
                </blockquote>
              </template>

              <div v-if="returnFilesCode('deploy')" class="mb-5">
                <div class='mt-7 mb-3'>{{$t("Fichier(s) concernant le déploiement :")}}</div>
                <template v-for="(item, index) in returnFilesCode('deploy')" :key="index">
                  <a class="btn btn-sm btn-light-primary px-4 py-2 me-4 mt-1 mb-2  mx-7 btn-dl mx-7" @click="downloadFile(item)" >
                    <i class="las la-file-alt fs-2 me-2"></i>
                    {{$t("Fichier :")}} {{ item.name }}
                  </a>
                </template>
              </div>


              <b>{{$t("Lieu du déploiement :")}}</b>
              <ul class="mt-3">           
                  <div class="text-gray-400">
                    <span class="w-150px  fs-6 text-gray-700" v-if="byCodeAdr('deploy')" >
                      <span  v-if="byCodeAdr('deploy')['adresse'].adr_c_site_label" class='text-primary'>{{ byCodeAdr('deploy')['adresse'].adr_c_site_label  }}<br></span>
                      <span>{{ byCodeAdr('deploy')['adresse'].adr_ligne_1  }}<br></span>
                      <span v-if="byCodeAdr('deploy')['adresse'].adr_ligne_2 ">{{ byCodeAdr('deploy')['adresse'].adr_ligne_2  }}<br></span>
                      <span>{{ byCodeAdr('deploy')['adresse'].adr_cp  }} - {{ byCodeAdr('deploy')['adresse'].adr_ville  }} - {{ byCodeAdr('deploy')['adresse'].adr_pays  }} </span>
                      <span v-if="byCodeAdr('deploy')['adresse'].con_prenom "><br><br><b>{{$t("Contact :")}}</b> {{ byCodeAdr('deploy')['adresse'].con_prenom  }} {{ byCodeAdr('deploy')['adresse'].con_nom  }}</span>
                    </span>
                  </div>
              </ul> 


            </div>

            <div v-if="byCode('deploy').tis_notes" class="text-gray-900 bg-white px-10 pb-10" style="width: 100%" >
              <div class="mb-2"><b>{{$t("Commentaires Stillnetwork :")}}</b></div>
              <el-alert class="p-5" type="info" :title="byCode('deploy').tis_notes" :closable="false"/>
            </div>

          </div>
        </div>


        <div class="mt-10" v-for="(item, i) in state.objTicket.tic_c_serv.filter(element => element.xCheckUser == true && element.code.indexOf('custom') >=0 )" :key="i"  style="display: flex; align-items: center; align-items: flex-start;">
          
          <div class="stepper-icon w-30px h-30px bg-light-dark pastille-ticket" v-if="getStatusA(item.code, item.tis_seq) <= 0">
            <span class="svg-icon svg-icon-3 svg-icon-dark pulse pulse-primary" >
              <inline-svg style="margin-top:6px; margin-left: 6px" src="media/icons/duotune/general/gen012.svg" />
            </span>
          </div>
          <div class="stepper-icon w-30px h-30px bg-success pastille-ticket" v-else>
            <span class="svg-icon svg-icon-3 svg-icon-white pulse pulse-primary">
              <inline-svg style="margin-top:6px; margin-left: 6px" src="media/icons/duotune/arrows/arr085.svg" />
            </span>
          </div>
          <div class="px-5" style="border-left: 1px dashed #e4e6ef; width: 100%">
            <h3 style="text-transform: uppercase; margin-left: 10px">{{ $t(item.name) }}</h3>
            <div class="text-gray-900 mt-5 bg-white rounded p-5" style="width: 100%">

              <template v-if="byCode(item.code).desc_globale">
                <div class='mt-7 mb-3'><b>{{$t("Description :")}}</b></div>
                <blockquote class="blockquote border-0 p-0 mx-7">
                  <p class="font-italic lead fs-6"> <i class="fa fa-quote-left mx-3  mr-3 text-primary"></i><span v-html="(byCode(item.code).desc_globale.replace(/(\r\n|\n\r|\r|\n)/g, '<br>'))"></span><i class="fa fa-quote-right mx-3  mr-3 text-primary" /></p>
                  
                </blockquote>
              </template>
              <div class="mt-4" v-if="item.cat_json_custom_values && JSON.stringify(item.cat_json_custom_values).length > 0">
                <br>
                <b>{{$t("Catégorisation :")}}</b> 
                <br><br>
                <div v-if="state.modeTic == 0" >
                  
                  <el-row :gutter="10">
                    <template v-for="(item2, index) in item.cat_json_custom" :key="index"> 
                      <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" v-if="item.cat_json_custom_values[item2.name]" >
                        <div class="bg-white p-8 shadow-xs">
                          <div class="text-gray-800 fw-bold fs-6 mb-2">
                            {{ item2.name }}
                          </div>
                          <el-select
                            v-model="item.cat_json_custom_values[item2.name]"
                            :placeholder="`Sélectionner ${item2.name}`"
                            size="large"
                            style="width: 100%"
                            class="disabled"
                            disabled
                          >
                            <el-option v-for="(item3, index3) in item2.values" :key="index3" :value="item3.value" :label="`${item3.icon}  ${item3.value}`" />

                          </el-select>

                        </div>
                      </el-col>
                    </template>
                  </el-row>

                </div>

                <div v-else >

                <br>
                <el-row :gutter="10">
                  <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" v-for="(item2, index) in item.cat_json_custom" :key="index">
                    <div class="bg-white p-8 shadow-xs">
                      <div class="text-gray-800 fw-bold fs-6 mb-2">
                        {{ item2.name }}
                      </div>
                      <el-select
                        v-model="item.cat_json_custom_values[item2.name]"
                        :placeholder="`Sélectionner ${item2.name}`"
                        size="large"
                        style="width: 100%"
                        @change='applyChange(item.tis_seq, item.cat_json_custom_values, item.cat_json_custom_values[item2.name], item2.name)'
                      >
                        <el-option v-for="(item3, index3) in item2.values" :key="index3" :value="item3.value" :label="`${item3.icon}  ${item3.value}`" />

                      </el-select>


                    </div>
                  </el-col>
                </el-row>

                </div>

              </div>

              <div class="mt-4">                
                <div class="mb-4" v-if="state.objTicket.integration && state.objTicket.integration.filter(el => el.int_knum_ticket_catserv == item.tis_seq).length > 0">      
                  
                  <div class="row d-flex justify-content-between align-middle mb-4">
                    <div class="col-sm-6">
                      <b>{{$t("Compte rendu d'activité :")}}</b>
                    </div>
                    <div class="col-sm-6 text-end" v-if="modeDisplay == 'Edit'">
                      <b>{{$t("Ajouter une prestation")}}</b>
                      <button type="button" class="btn btn-sm btn-secondary mx-5" @click="addPresta()"><i class="bi bi-plus-lg"></i></button>
                    </div>
                  </div>
                  <div class="text-end mb-4" v-if="modeDisplay == 'Edit'">                    
                    <span class="py-1 m-2 nobg">{{$t("Nombre de jours sur la commande :")}}</span>
                    <span class="fw-bolder py-1 m-2 nobg">{{ formatNumber(state.objTicket.integration[currIndex].tot_commande) }}</span>
                    <template v-if="state.objTicket.integration[currIndex].othersTot > 0">
                      <span class="py-1 m-2 nobg">{{$t("Total sur autres prestations :")}}</span>
                      <span class="fw-bolder py-1 m-2 nobg">{{ state.objTicket.integration[currIndex].othersTot }}</span>
                    </template>
                    
                  
                    <span class="py-1 m-2 nobg">{{$t("Total prestation en cours :")}}</span>
                    <span class="fw-bolder py-1 m-2 nobg">{{ state.objTicket.integration[currIndex].myTot }}</span>
                    <span class="py-1 m-2 nobg">{{$t("Soit un solde restant de :")}}</span>
                    <span class="fw-bolder py-1 m-2 nobg">{{ state.objTicket.integration[currIndex].solde }}</span>
                  </div>
                  <div class="container mb-4">
                    <Datatable
                      v-if="state.objTicket.integration && state.objTicket.integration.filter(el => el.int_knum_ticket_catserv == item.tis_seq).length > 0"
                      :table-data="state.objTicket.integration.filter(el => el.int_knum_ticket_catserv == item.tis_seq)"
                      :table-header="tableHeader"
                      :enable-items-per-page-dropdown="false"
                      empty-table-text="Aucune prestation à afficher."
                      :key="state.loadingDatatable"
                    >
                      <template v-slot:cell-int_designation="{ row: dataEl }">
                        <span v-if="modeDisplay == 'Edit'">
                          <el-input
                            v-model="compIntTis(item.tis_seq).find(el => el.int_seq == dataEl.int_seq).int_designation"
                            :autosize="{ minRows: 2, maxRows: 20 }"
                            type="textarea"
                            :placeholder=dataEl.int_designation
                            style="width: 340px;"
                            @blur="updateLigneIntegration(dataEl)"
                          />
                        </span>
                        <span class="fw-bolder py-1 m-2 nobg" v-else>
                          {{ dataEl.int_designation }}
                        </span>
                      </template>
                      <template v-slot:cell-int_qte="{ row: dataEl }">                      
                        <div class="text-muted fw-bold d-block mt-2 m-titre" v-if="modeDisplay == 'Edit'">
                          <el-input                              
                            v-model="compIntTis(item.tis_seq).find(el => el.int_seq == dataEl.int_seq).int_qte"
                            style="width: 60px;"
                            :placeholder="formatNumber(dataEl.int_qte)"
                            @blur="updateLigneIntegration(dataEl)"
                          />
                          <span v-if="dataEl.int_qte>0"> jour</span><span v-if="dataEl.int_qte > 1">s</span>                              
                        </div>    
                        <div class="fw-bolder py-1 m-2 nobg" v-else>
                          {{ formatNumber(dataEl.int_qte) }}
                          <span v-if="dataEl.int_qte>0"> jour</span><span v-if="dataEl.int_qte > 1">s</span>
                        </div>                  
                      </template>

                      <template v-slot:cell-int_date_inter="{ row: dataEl }">
                        <span 
                          v-if="modeDisplay == 'Edit'"
                          class="text-primary fw-bolder py-1 m-2 nobg" 
                          style="font-size: smaller;"
                        >
                        
                          <el-date-picker 
                            v-model="compIntTis(item.tis_seq).find(el => el.int_seq == dataEl.int_seq).int_date_inter"
                            format="DD/MM/YYYY" 
                            value-format="YYYY-MM-DD" 
                            size="small" 
                            style="width: 140px" 
                            type="date" 
                            :placeholder="$t('Planifier')"
                            @change="updateLigneIntegration(dataEl)"                               
                            />
                        </span>
                        <span class="fw-bolder py-1 m-2 nobg" v-else>
                          <span v-if="dataEl.int_date_inter">{{ formatDate(dataEl.int_date_inter) }}</span>
                          <span v-else>{{ $t('Date non définie') }}</span>
                        </span>
                      </template>
                      <template v-slot:cell-int_status="{ row: dataEl}">
                        <span v-if="modeDisplay == 'Edit'">
                          <el-select 
                            v-model="dataEl.int_status" 
                            class="m-1 nobg w-150px" 
                            :placeholder="dataEl.int_status ? dataEl.int_status : $t('A définir')" 
                            size="small"   
                            @change="updateLigneIntegration(dataEl)"    
                          >
                            <el-option
                              v-for="status in dataEl.all_status.split(', ')"
                              :key="status"
                              :value="status"
                              :label="`${status == 'Recetté'  ? '✅' : '⌛'} ${status}`"
                            >
                            <span v-if="status === 'Recetté'">✅ {{ status }}</span>
                            <span v-else>⌛ {{  status }}</span>
                            </el-option>
                          </el-select>
                          </span>
                        <span class="fw-bolder py-1 m-2 nobg" v-else>
                          {{ dataEl.int_status === 'Recetté' ? '✅ ' + dataEl.int_status : '⌛ ' + dataEl.int_status }}
                        </span>
                      </template>
                      <template v-slot:cell-presta_nom_prenom=" { row: dataEl}">
                        <span v-if="modeDisplay == 'Edit'">
                          <el-select v-model="dataEl.int_knum_contact"
                            :placeholder="dataEl.presta_nom_prenom ? dataEl.presta_nom_prenom : $t('A définir')" 
                            class="m-2 nobg" size="small" 
                            style="width: 120px;"
                            @change="updateLigneIntegration(dataEl)"
                            >
                            <el-option
                              v-for="contact in state.allContacts"
                              :key="contact.value"
                              :label="contact.con_nom_prenom_ab"
                              :value="contact.con_seq"
                            />
                          </el-select>
                        </span>
                        <span class="fw-bolder py-1 m-2 nobg" v-else>
                          {{ dataEl.presta_nom_prenom }}
                        </span>
                      </template>
                      <template v-slot:cell-int_seq=" { row: dataEl}">
                        <span v-if="modeDisplay == 'Edit'">                          
                          <el-popconfirm                             
                            :confirm-button-text="$t('Oui, le supprimer !')"
                            :cancel-button-text="$t('Non')"
                            icon-color="primary"
                            :title="$t('Opération irréversible, voulez-vous supprimer cette prestation ?')"
                            @confirm="deletePresta(dataEl.int_seq)"
                          >
                            <template #reference>
                              <a 
                                class="btn btn-sm btn-light-danger" 
                                style="width:50px">
                                  <i class="las la-trash fs-2 me-1"></i>
                              </a>
                            </template>
                          </el-popconfirm>                          
                        </span>
                      </template>
                    </Datatable>
                  </div>                 
                </div> 
                <div class="mt-8">
                  <b>{{$t("Statut d'intervention :")}}</b>

                  <ul class="mt-3">           
                      <div class="text-gray-400">
                        <span class="w-150px  fs-6 text-gray-700" v-if="byCodeAdr(item.code)" >
                          <span v-if="item.date1" class='text-gray-800'>{{$t("Première date d'intervention souhaitée le")}} {{ formatDate(item.date1) }}.<br></span>
                          <span v-if="item.date2" class='text-gray-800'>{{$t("Seconde date d'intervention souhaitée le")}} {{ formatDate(item.date2) }}.<br></span>
                          <span v-if="item.date3" class='text-gray-800'>{{$t("Troisième date d'intervention souhaitée le")}} {{ formatDate(item.date3) }}.<br></span>
                          <span v-if="item.tis_date_planif" class='text-gray-800'><b>{{$t("Intervention du technicien en date du")}} {{ formatDate(item.tis_date_planif) }}.</b><br></span>
                          <span v-else class='text-gray-800'><b>{{$t("Intervention du technicien en cours de planificiation.")}}</b><br></span>
                          <span v-if="item.technicien_seq" class='text-gray-800'>{{$t("Technicien effectuant la prestation :")}} {{ item.technicien }}.<br></span>
                        </span>
                      </div>
                  </ul> 
                </div>
                
              </div>

              <div v-if="returnFilesCode(item.code)" class="mb-5">
                <div class='mt-7 mb-3'><b>{{$t("Fichier(s) joint :")}}</b></div>
                <template v-for="(item2, index) in returnFilesCode(item.code)" :key="index">
                  <a class="btn btn-sm btn-light-primary px-4 py-2 me-4 mt-1 mb-2  mx-7 btn-dl mx-7" @click="downloadFile(item2)" >
                    <i class="las la-file-alt fs-2 me-2"></i>
                    {{$t("Fichier :")}} {{ item2.name }}
                  </a>


                      <el-popconfirm
                        v-if="state.modeTic"
                        :confirm-button-text="$t('Oui, le supprimer !')"
                        :cancel-button-text="$t('Non')"
                        icon-color="primary"
                        :title="$t('Opération irréversible, voulez-vous supprimer ce fichier ?')"
                        @confirm="delFile(item2.id,item.tis_seq)"
                      >
                        <template #reference>
                          <a  class="btn btn-sm btn-light-danger" style=" margin: 10px; width:150px"><i class="las la-trash fs-2 me-2"></i>{{$t("Supprimer")}}</a>
                        </template>
                      </el-popconfirm>            


                </template>
              </div>
              <GalerieImage :listImage="returnImgCode(item.code)" :modeTic="state.modeTic" :tic_seq="state.objTicket.tic_seq" :tis_seq="item.tis_seq"  @reloadTicket="reloadTicket($event)"/>
              <br>
              <b>{{$t("Adresse :")}}</b>
              <ul class="mt-3">           
                  <div class="text-gray-400">
                    <span class="w-150px  fs-6 text-gray-700" v-if="byCodeAdr(item.code)" >
                      <span  v-if="byCodeAdr(item.code)['adresse'].adr_c_site_label" class='text-primary'>{{ byCodeAdr(item.code)['adresse'].adr_c_site_label  }}<br></span>
                      <span>{{ byCodeAdr(item.code)['adresse'].adr_ligne_1  }}<br></span>
                      <span v-if="byCodeAdr(item.code)['adresse'].adr_ligne_2 ">{{ byCodeAdr(item.code)['adresse'].adr_ligne_2  }}<br></span>
                      <span>{{ byCodeAdr(item.code)['adresse'].adr_cp  }} - {{ byCodeAdr(item.code)['adresse'].adr_ville  }} - {{ byCodeAdr(item.code)['adresse'].adr_pays  }} </span>
                      <span v-if="byCodeAdr(item.code)['adresse'].con_prenom "><br><br><b>{{$t("Contact :")}}</b> {{ byCodeAdr(item.code)['adresse'].con_prenom  }} {{ byCodeAdr(item.code)['adresse'].con_nom  }}</span>
                    </span>
                  </div>
              </ul> 


            </div>

            <template v-if="!state.modeTic">
              <div v-if="byCode(item.code).tis_notes" class="text-gray-900 bg-white px-10 pb-10" style="width: 100%" >
                <div class="mb-2"><b>{{$t("Commentaires Stillnetwork :")}}</b></div>
                <el-alert class="p-5" type="info" :title="byCode(item.code).tis_notes" :closable="false"/>
              </div>
            </template>
            <template v-else >
              <div  class="text-gray-900 bg-white px-10 pb-10" style="width: 100%" >
                <div class="mb-2"><b>{{$t("Modifier les commentaires Stillnetwork :")}}</b></div>
                  <el-input
                  v-model="byCode(item.code).tis_notes"
                  :rows="4"
                  type="textarea"
                  :placeholder="$t('Saisir vos commentaires')"
                  />
                  <a class="btn btn-sm btn-light-success me-2 mb-2 btn-text-dark mt-2" @click="updateCom(byCode(item.code).tis_seq, byCode(item.code).tis_notes) "
                    v-if="byCode(item.code).old_tis_notes != byCode(item.code).tis_notes"
                  >
                    <span class="svg-icon svg-icon-1 svg-icon-white"><inline-svg src="media/icons/duotune/general/gen043.svg"/> </span>
                    {{$t("Appliquer")}}
                  </a>
              </div>
            </template>

          </div>
        </div>


        <div class="mt-10" v-if="state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == 'factu')]"
            style="display: flex; align-items: center; align-items: flex-start;">
          <div class="stepper-icon w-30px h-30px bg-light-dark pastille-ticket" v-if="getStatusA('factu') <= 0">
            <span class="svg-icon svg-icon-3 svg-icon-dark pulse pulse-primary" >
              <inline-svg style="margin-top:6px; margin-left: 6px" src="media/icons/duotune/general/gen012.svg" />
            </span>
          </div>
          <div class="stepper-icon w-30px h-30px bg-success pastille-ticket" v-else>
            <span class="svg-icon svg-icon-3 svg-icon-white pulse pulse-primary">
              <inline-svg style="margin-top:6px; margin-left: 6px" src="media/icons/duotune/arrows/arr085.svg" />
            </span>
          </div>
          <div class="px-5" style="border-left: 1px dashed #e4e6ef; width: 100%">
            <h3 style="text-transform: uppercase; margin-left: 10px">{{$t("Facturation")}}</h3>
            <div class="text-gray-900 mt-5 bg-white rounded p-5" style="width: 100%">
              
              <b><el-alert :title="$t('Ce ticket a été facturé')" class="h-60px mt-2 mb-5" type="success" description="" :closable="false" v-if="!(getStatusA('factu') <= 0)" /></b>

              <b>{{$t("Adresse de facturation :")}}</b>
              <ul class="mt-3">           
                  <div class="text-gray-400">
                    <span class="w-150px  fs-6 text-gray-700" v-if="byCodeAdr('factu')" >
                      <span  v-if="byCodeAdr('factu')['adresse'].adr_c_site_label" class='text-primary'>{{ byCodeAdr('factu')['adresse'].adr_c_site_label  }}<br></span>
                      <span>{{ byCodeAdr('factu')['adresse'].adr_ligne_1  }}<br></span>
                      <span v-if="byCodeAdr('factu')['adresse'].adr_ligne_2 ">{{ byCodeAdr('factu')['adresse'].adr_ligne_2  }}<br></span>
                      <span>{{ byCodeAdr('factu')['adresse'].adr_cp  }} - {{ byCodeAdr('factu')['adresse'].adr_ville  }} - {{ byCodeAdr('factu')['adresse'].adr_pays  }} </span>
                      <span v-if="byCodeAdr('factu')['adresse'].con_prenom "><br><br><b>{{$t("Contact :")}}</b> {{ byCodeAdr('factu')['adresse'].con_prenom  }} {{ byCodeAdr('factu')['adresse'].con_nom  }}</span>
                    </span>
                  </div>
              </ul> 

              <div v-if="returnFilesCode('factu')" class="mb-5">
                <div class='mt-7 mb-3'>{{$t("Fichier(s) joint :")}}</div>
                <template v-for="(item, index) in returnFilesCode('factu')" :key="index">
                  <a class="btn btn-sm btn-light-primary px-4 py-2 me-4 mt-1 mb-2  mx-7 btn-dl mx-7" @click="downloadFile(item)" >
                    <i class="las la-file-alt fs-2 me-2"></i>
                    {{$t("Fichier :")}} {{ item.name }}
                  </a>
                </template>
              </div>

            </div>

            <div v-if="byCode('factu').tis_notes" class="text-gray-900 bg-white px-10 pb-10" style="width: 100%" >
              <div class="mb-2"><b>{{$t("Commentaires Stillnetwork :")}}</b></div>
              <el-alert class="p-5" type="info" :title="byCode('factu').tis_notes" :closable="false"/>
            </div>

          </div>
        </div>

      </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, computed } from "vue";
import * as moment from "moment";
import { ElNotification, ElMessageBox } from 'element-plus'
import { useI18n } from "vue-i18n";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";

import GalerieImage from "@/views/still/deploy/galerieImage.vue";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "Affichage d'un ticket",
  emits: ["reloadTicket", "updateStatusFromChild"],
  props: {
    objTicket : {
      default: {"tic_knum_societe": "", "tic_c_label": "", "tic_c_serv": [], "tic_list_matos": [],"tic_staging": [], "integration": []},
    },
    modeTic : {
      default: 0,
    },
  },
  components: {
    GalerieImage,
    Datatable    
  },
  setup(props, { emit }) {
    //moment.default.locale("fr");
    const state = reactive({
      loaderEnabled: true,
      objTicket: props.objTicket as any,
      modeTic: props.modeTic as any,
      mAdresse: [] as any,
      activeStep : 0,
      stagingOnOff: 0,
      loadingDatatable: 0,
      allContacts: [] as any,
      readOnly: false,
      currIndex: 0,
      compIntTis: [] as any,
    });
    const { t } = useI18n()
   
    onMounted(async () => {
      // console.log('objet', state.objTicket)

      let count = 0;
      state.objTicket.tic_c_serv.forEach(async (val) => { 

        val.old_tis_notes = val.tis_notes;

        val.cat_json_custom_values = isJSON(val.cat_json_custom_values) ? JSON.parse(val.cat_json_custom_values) : val.cat_json_custom_values;

        val.cat_json_custom = isJSON(val.cat_json_custom) ? JSON.parse(val.cat_json_custom) : val.cat_json_custom;

        if (val.knum_lieu && val.code) await returnAdresse(val.code, val.knum_lieu, val.knum_contact);
        count += 1;

        // if (val.tis_status != 'En cours') state.activeStep += 1;
        if (getStatusA(val.code, val.tis_seq) == 1 ) state.activeStep += 1;


        if( count == state.objTicket.tic_c_serv.length) state.loaderEnabled = false;

        if (val.tis_status == 'Réalisé') state.readOnly = true;

        if (state.objTicket.integration && state.objTicket.integration.filter(el => el.int_knum_ticket_catserv == val.tis_seq).length > 1) {
          let totalGroupe = 0;
          for (let i = 0; i < state.objTicket.integration.length; i++) {
            if (state.objTicket.integration[i].int_knum_ticket_catserv == val.tis_seq) {
              totalGroupe += parseFloat(state.objTicket.integration[i].tot_commande);
            }
          }
                    
          // Assigner totalGroupe à toutes les lignes correspondantes
          for (let i = 0; i < state.objTicket.integration.length; i++) {
            if (state.objTicket.integration[i].int_knum_ticket_catserv == val.tis_seq) {
              state.objTicket.integration[i].tot_commande = totalGroupe.toString();
            }
          }
        }
      });

      let myContacts = await mAxiosApi.prototype.getAxios("/getContacts");
      state.allContacts = myContacts.results;

      state.objTicket.integration.forEach(line => {
        getMyTotal(line.int_knum_contact, line.int_knum_ticket_catserv);
        getTotOthers(line.int_knum_ticket_catserv, line.int_knum_lng_commande);
      });
      getSolde();

      state.currIndex = state.objTicket.integration.findIndex(el => el.int_knum_ticket_catserv == state.objTicket.tic_c_serv[0].tis_seq);

    });


    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };

    const byCode = (code) => {
      if (state.objTicket) return state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.code == code)]
    }

    function isJSON(str) {
        try {
            return (JSON.parse(str) && !!str);
        } catch (e) {
            return false;
        }
    }

    const reloadTicket = () => {
      emit("reloadTicket", 1);
    };

    const delFile = (tif_seq,tis_seq) => {
      const form = {
       tif_knum_ticket: state.objTicket.tic_seq,
       tif_knum_ticket_services: tis_seq,
        tif_seq: tif_seq,
      }

      mAxiosApi
        .post("/delFileTicketService", form)
        .then( async() => {
            emit("reloadTicket", 1);
        })
    };

    const updateCom = (tis_seq, newCom) => {
      const form = {
       ticSeq: state.objTicket.tic_seq,
       tisSeq: tis_seq,
       newCom: newCom,
      }

      mAxiosApi
        .post("/updateComTicketService", form)
        .then( async() => {
          emit("reloadTicket", 1);
          ElNotification({
            title: t("Succès"),
            message: t("Commentaires modifié"),
            type: 'success',
          })

        })
    };
     

    const returnFilesCode = (code) => {
      if (!state.objTicket) return false;
      const fichiers = state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.code == code)].fichiers;

      if (isJSON(fichiers)) {
        if (JSON.parse(fichiers).length > 0) {
          let filesArray = JSON.parse(fichiers).filter(el => el.name.indexOf('.jpg') <= 0);
          filesArray = filesArray.filter(el => el.name.indexOf('.png') <= 0);
          filesArray = filesArray.filter(el => el.name.indexOf('.jpeg') <= 0);
          filesArray = filesArray.filter(el => el.name.indexOf('.JPG') <= 0);
          filesArray = filesArray.filter(el => el.name.indexOf('.gif') <= 0);
          return filesArray;
        }  
        return false;
      } else {
        if (fichiers.length > 0) return fichiers;
        return false;
      }
    }

    const returnImgCode = (code) => {
      if (!state.objTicket) return false;
      const fichiers = state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.code == code)].fichiers;

      if (isJSON(fichiers)) {
        if (JSON.parse(fichiers).length > 0) {
          let filesArray = JSON.parse(fichiers).filter(el => el.name.indexOf('.jpg') > 0 || el.name.indexOf('.jpeg') > 0 || el.name.indexOf('.JPG') > 0 || el.name.indexOf('.png') > 0 || el.name.indexOf('.gif') > 0);
          return filesArray;
        }  
        return false;
      } else {
        if (fichiers.length > 0) return fichiers;
        return false;
      }
    }

    const byCodeAdr = (code) => {
      if (state.mAdresse) return state.mAdresse[state.mAdresse.findIndex(element => element.code == code)]
    }
    const returnAdresse = async (code, adr_seq, con_seq) => {
      let mAdresse = await mAxiosApi.prototype.getAxios("/getAdresseContact/" + adr_seq + '/' + con_seq);
      state.mAdresse.push({ code: code, adresse: mAdresse.records } )
    }

    const applyChange = (tis_seq, values_custom, name, value) => {

      const form = {
       ticSeq: state.objTicket.tic_seq,
       tisSeq: tis_seq,
       values_custom: values_custom,
       name: name,
       value: value,
      }

      mAxiosApi
        .post("/updateCustomValues", form)
        .then( async() => {
          //emit("reloadTicket", 1);
          ElNotification({
            title: t("Succès"),
            message: t("Valeur de '") + name + t("' modifiée."),
            type: 'success',
          })
      })

    }

    const getStatus = (code) => {
      /*
       if (!state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].tis_status) return '';
      return state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].tis_status;
      */
      if (!state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)]?.tis_status) return '';
      return state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)]?.tis_status;
    }

    const getStatusA = (code, tisSeq = null) => {

      // const currTis = state.objTicket.tic_c_serv.find(element => 
      //   element.xCheckUser == true && 
      //   element.code == code && 
      //   (element.tis_seq == tisSeq)
      // );

      // if (!currTis || !currTis.tis_status) return -1;

      // let catStatus = currTis.cat_status;
      // const arrayCat = JSON.parse(catStatus);

      // return arrayCat.status.find(element => element.label == currTis.tis_status)?.next;

      /*
       if (!state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].tis_status) return '';
      return state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].tis_status;
      */
      if (!state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)]?.tis_status) return -1;
      let catStatus;
      if (tisSeq) {
        catStatus = state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code && element.tis_seq == tisSeq)].cat_status;
      } else {
        catStatus = state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].cat_status;
      }
      
      const arrayCat = JSON.parse(catStatus);
      // console.log('status : ',arrayCat.status.find(element => element.label==state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].tis_status).next);

      return arrayCat.status.find(element => element.label==state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)]?.tis_status).next;
    }

    function b64toBlob(dataURI) {
        
        var byteString = atob(dataURI.split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'text/plain' });
    }

    const downloadFile = async (item) => {
      
      console.log("En attente");
      let myList = await mAxiosApi.prototype.getAxios("/getFileTicket/" + item.id + '/' + item.name);
//      const myBlob = myList.results[0].tif_base64.substring(myList.results[0].tif_base64.indexOf('base64,')+7);

      // console.log(myList.results[0].tif_base64.blob());
      /*
      const data = encodeURI(myList.results[0].tif_base64);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", item.name);
      link.click();
      */
      
      const csvData = b64toBlob(myList.results[0].tif_base64);
      const csvUrl = URL.createObjectURL(csvData);
      const element = document.createElement("a");
      element.setAttribute("href",csvUrl);
      element.setAttribute("download", item.name);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    };

    const tableHeader = computed(() => {
      const headers = [
        {
          name: 'Désignation',
          key: "int_designation",
          sortable: true,
        },
        {
          name: 'Date',
          key: 'int_date_inter',
          sortable: true
        },
        { name: 'Statut',
          key: 'int_status',
          sortable: true
        },
        {
          name: 'Intervenant',
          key: 'presta_nom_prenom',
          sortable: true
        },
        {
          name: 'Jours',
          key: 'int_qte',
          sortable: true
        }
      ];

      if(modeDisplay.value == 'Edit') {
        headers.push({
          name: 'Actions',
          key: 'int_seq',
          sortable: true 
        });
      }

      return headers;
    })
      

    function formatNumber(value) {
      if (value === undefined || value === null) {
        return '';
      }
      let [integer, decimal] = value.toString().split('.');
      return decimal && decimal.startsWith('0') ? integer : `${integer}.${decimal ? decimal[0] : ''}`;
    }

    function addPresta () {

      const form = {
        tot_commande: state.objTicket.integration[currIndex.value].tot_commande,
        myTot: state.objTicket.integration[currIndex.value].myTot,
        othersTot: state.objTicket.integration[currIndex.value].othersTot,
        solde: state.objTicket.integration[currIndex.value].solde,
        int_seq: 0,
        int_date_creation: state.objTicket.integration[currIndex.value].int_date_creation,
        int_knum_user_creation: state.objTicket.integration[currIndex.value].int_knum_user_creation,
        int_knum_lng_commande: state.objTicket.integration[currIndex.value].int_knum_lng_commande,
        int_qte: 0,
        int_date_inter: state.objTicket.integration[currIndex.value].int_date_inter,
        int_knum_contact: state.objTicket.integration[currIndex.value].int_knum_contact,
        int_status: 'A Planifier',
        int_designation: state.objTicket.integration[currIndex.value].int_designation,
        int_knum_ticket_catserv: state.objTicket.integration[currIndex.value].int_knum_ticket_catserv,
        all_status: state.objTicket.integration[currIndex.value].all_status,
        intervenant: state.objTicket.integration[currIndex.value].intervenant,
        presta_nom_prenom: state.objTicket.integration[currIndex.value].presta_nom_prenom,
        
      }

      mAxiosApi
        .post("/addLigneIntegration", form)
        .then(async (response) => {
          const erreurs = response.data.erreurs;
          if (erreurs && erreurs.length > 0) {
            ElMessageBox.alert(
              t(erreurs[0].message), t("Attention !"),
              { confirmButtonText: t("Ok"), type: 'warning' }
            );
            return;
          } else {

            form.int_seq = response.data.int_seq;
            state.objTicket.integration.push(form);
            state.loadingDatatable ++;
            
            ElNotification({
              title: t("Succès"),
              message: t("Intégration ajoutée"),
              type: 'success',
            })
          }
        })      
    }

    const deletePresta = (int_seq) => {
      const form = {
        int_seq: int_seq,
      }

      state.objTicket.integration.splice(state.objTicket.integration.findIndex(el => el.int_seq == int_seq), 1);

      state.loadingDatatable ++;

      mAxiosApi
        .post("/deleteLigneIntegration", form)
        .then(async () => {          
          ElNotification({
            title: t("Succès"),
            message: t("Intégration supprimée"),
            type: 'success',
          })

          getMyTotal(state.objTicket.integration[currIndex.value].int_knum_contact, state.objTicket.integration[currIndex.value].int_knum_ticket_catserv);
          getTotOthers(state.objTicket.integration[currIndex.value].int_knum_ticket_catserv, state.objTicket.integration[currIndex.value].int_knum_lng_commande);
          getSolde();
        })
    }

    const updateLigneIntegration = (dataEl) => {
      if (dataEl.int_knum_contact == null) {
        ElMessageBox.alert(
          t('Veuillez définir le prestataire avant de modifier la prestation svp.'), t("Erreur"),
          { confirmButtonText: t("Ok"), type: 'error', }
        );
        return;
      }

      if (dataEl.int_qte < 0) {
        ElMessageBox.alert(
          t('La quantité ne peut pas être négative.'), t("Erreur"),
          { confirmButtonText: t("Ok"), type: 'error', }
        );
        return;
      }

      if (dataEl.int_status || dataEl.int_knum_contact) {
        state.objTicket.integration.find(el => el.int_seq == dataEl.int_seq).int_status = dataEl.int_status;
        state.objTicket.integration.find(el => el.int_seq == dataEl.int_seq).int_knum_contact = dataEl.int_knum_contact;
      }

      const form = {
        int_seq: dataEl.int_seq,
        int_date_inter: dataEl.int_date_inter,
        int_designation: dataEl.int_designation,
        int_qte: dataEl.int_qte,
        int_knum_contact: dataEl.int_knum_contact,
        int_status: dataEl.int_status,
        int_knum_lng_commande: state.objTicket.integration[currIndex.value].int_knum_lng_commande,
        int_knum_ticket_catserv: dataEl.int_knum_ticket_catserv
      }

      // console.log('form : ', form);

      mAxiosApi
        .post("/updateLigneIntegration", form)
        .then(async (response) => {

          const block = response.data.block;
          const erreurs = response.data.erreurs;
          const modification = response.data.modification;

          if (state.objTicket.integration[0].solde === 0 && (erreurs && erreurs.some(err => err.type === 'cloture'))) {
            updateStatusFromChild(state.objTicket.tic_c_serv[0]);

            if (state.objTicket.tic_c_serv[0].tis_status == 'Réalisé') {
              state.readOnly = true;
            }
          }

          if (block) {
            ElMessageBox.alert(
              t(block[0].message), t("Attention !"),
              { confirmButtonText: t("Ok"), type: 'error' }
            );
            return;
          }

          if (erreurs && erreurs.length > 0) {
            for (const [fullKey, value] of Object.entries(modification)) {
              const [section, key] = fullKey.split('.');


              if (state.objTicket[section]) {
                if (section === 'tic_c_serv') {

                  const target = state.objTicket[section];
                  
                  if (target) {
                    target[0][key] = value;
                  }

                } else {

                  const target = state.objTicket[section].find(el => el.int_seq == dataEl.int_seq);

                  if (target) {
                    target[key] = value;
                  }
                }
                getMyTotal(dataEl.int_knum_contact, dataEl.int_knum_ticket_catserv);
                getTotOthers(dataEl.int_knum_ticket_catserv, dataEl.int_knum_lng_commande);
                getSolde();
              }
             
            }

            ElMessageBox.alert(
              t(erreurs[0].message), t("Attention !"),
              { confirmButtonText: t("Ok"), type: 'warning' }
            );
          } else {
            
            ElNotification({
              title: t("Succès"),
              message: t("Intégration modifiée."),
              type: 'success',
                });
          }
          
          if (state.objTicket.tic_c_serv[0].tis_status == 'Réalisé') {
            state.readOnly = true;
          }
          
        });

        getMyTotal(dataEl.int_knum_contact, dataEl.int_knum_ticket_catserv);
        getTotOthers(dataEl.int_knum_ticket_catserv, dataEl.int_knum_lng_commande);
        getSolde();

    }

    const compIntTis = (tisSeq) => {
      return state.objTicket.integration.filter(el => el.int_knum_ticket_catserv == tisSeq);
    };

    const getMyTotal = (technicienSeq, tisSeq) => {
      let myTot = 0;
      state.objTicket.integration.filter(el => el.int_knum_contact == technicienSeq && el.int_knum_ticket_catserv == tisSeq).forEach(line => {
        myTot += Number(line.int_qte);
      });
      state.objTicket.integration.forEach(line => {
        if (line.int_knum_contact == technicienSeq && line.int_knum_ticket_catserv == tisSeq) {
          line.myTot = myTot;
        }
      });
    };

    const getTotOthers = (tisSeq, lngCommande) => {
      let othersTot = 0;
      state.objTicket.integration
        .filter(el => el.int_knum_ticket_catserv != tisSeq && el.int_knum_lng_commande == lngCommande)
        .forEach(line => {
        othersTot += Number(line.int_qte);
      });
      state.objTicket.integration.forEach(line => {
        if (line.int_knum_ticket_catserv == tisSeq && line.int_knum_lng_commande == lngCommande) {
          line.othersTot = othersTot;
        }
      });
    };

    const getSolde = () => {
      let solde = 0;

      const totCommande = state.objTicket.integration[currIndex.value].tot_commande;
      const othersTot = state.objTicket.integration[currIndex.value].othersTot;
      const myTot = state.objTicket.integration[currIndex.value].myTot;
      
      
      solde = totCommande - (othersTot + myTot);
      state.objTicket.integration.forEach(line => {        
          line.solde = solde;
      });
      
      // console.log('solde : ', solde);
    }  

    const getTotLigneCommande = (intKnumLngCommande) => {
      let totCommande = 0;
      state.objTicket.integration.forEach(line => {
        if (line.int_knum_lng_commande == intKnumLngCommande) {
          totCommande += Number(line.int_qte);
        }
      });
      return totCommande;
    }
    
    const updateStatusFromChild = (data) => {
      emit("updateStatusFromChild", data);
    };

    const modeDisplay = computed(() => { 
      if (!state.modeTic || state.readOnly) {
        return 'ReadOnly';
      } else {
        return 'Edit';
      }
    });

    const currIndex = computed(() => {
      return state.objTicket.integration.findIndex(el => el.int_knum_ticket_catserv == state.objTicket.tic_c_serv[0].tis_seq);
    });

   return {
      state,
      byCode,
      formatDate,
      returnAdresse,
      byCodeAdr,
      getStatus,
      getStatusA,
      downloadFile,
      returnFilesCode,
      returnImgCode,
      reloadTicket,
      delFile,
      updateCom,
      applyChange,
      tableHeader,
      formatNumber,
      addPresta,
      deletePresta,
      updateLigneIntegration,
      getMyTotal,
      getTotOthers,
      getSolde,
      updateStatusFromChild,
      modeDisplay,
      currIndex,
      compIntTis,
      getTotLigneCommande
    };
  },
});
</script>
